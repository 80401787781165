import React from 'react';
import { FcPlus, FcBullish, FcStatistics, FcDocument, FcTimeline } from 'react-icons/fc';

/**
 * props:
 * - onClick
 */
function NewOrderTemplateButton() {
    return (
        <a className="btn btn-primary animate-up-2" href="/orderTemplate/">
            <FcTimeline />
            Novo Modelo de Ordens
        </a>
    );
}

export default NewOrderTemplateButton;