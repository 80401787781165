import React, { useRef, useState, useEffect } from 'react';
import MarginSelect from '../../components/MarginSelect';
import { updateFuturesPosition } from '../../services/ExchangeService';

/**
 * props:
 * - position
 * - onSubmit
 */
function MarginModal(props) {

    const [position, setPosition] = useState(null);
    const [error, setError] = useState('');
    const [marginType, setMarginType] = useState('CROSSED');
    const btnClose = useRef('');

    function onSubmit(event) {
        updateFuturesPosition(position.symbol, { marginType })
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.response ? err.response.data : err.message);
            })
    }

    useEffect(() => {
        if (!props.position) return;
        setPosition(props.position);
    }, [props.position])

    function getMarginType(position) {
        if (position && position.marginType)
            return `Margin ${position.marginType.toUpperCase()}`;
        return "Carregando Margem...";
    }

    return (
        <>
            <button className='btn btn-secondary col-12' data-bs-toggle="modal" data-bs-target="#modalMargin">
                {getMarginType(position)}
            </button>
            <div className="modal fade" id="modalMargin" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title" id="modalTitleNotify">
                                {position ? position.symbol : ""} Perpetuo
                            </p>
                            <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="marginType">Modo de Margem:</label>
                                            <MarginSelect marginType={marginType.toUpperCase()} onChange={(event) => setMarginType(event.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <p>
                                            Mudar o modo de margem só o aplicará ao contrato selecionado.
                                        </p>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>
                                            <b>Modo Margem Cruzada:</b> Todas as posições cruzadas sob o mesmo ativo de margem compartilham o mesmo saldo de margem cruzada do ativo. No caso de liquidação, o saldo total da margem de seus ativos, juntamente com quaisquer posições abertas restantes sob o ativo, pode ser perdido.
                                        </p>
                                        <p>
                                            <b>Modo Margem Isolada:</b> Gerencie seu risco em posições individuais, restringindo a quantidade de margem alocada para cada uma. Se o índice de margem de uma posição atingir 100%, a posição será liquidada. A margem pode ser adicionada ou removida de posições usando este modo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {
                                error
                                    ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                    : <></>
                            }
                            <button type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarginModal;
