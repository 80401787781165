import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import MonitorRow from './MonitorRow';
import { getMonitors, startMonitor, stopMonitor, deleteMonitor } from '../../services/MonitorsService';
import Pagination from '../../components/Pagination';
import MonitorModal from './MonitorModal/MonitorModal';
import Toast from '../../components/Toast';
import LogModal from '../../components/Logs/LogModal';
import NewMonitorButton from './NewMonitorButton';
import { FcKey, FcCurrencyExchange, FcElectricity } from 'react-icons/fc';
import { FcSupport, FcTabletAndroid } from 'react-icons/fc';

function Monitors() {

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        setPage(getPage(defaultLocation));
    }, [defaultLocation])

    const [monitors, setMonitors] = useState([]);

    const [count, setCount] = useState(0);

    const [notification, setNotification] = useState({ type: '', text: '' });

    const [editMonitor, setEditMonitor] = useState({});

    const [page, setPage] = useState(getPage());

    useEffect(() => {
        getMonitors(page || 1)
            .then(result => {
                setMonitors(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page])

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        const monitor = monitors.find(m => m.id == id);
        setEditMonitor({...monitor});
    }

    function onLogsClick(event) {
        const id = event.target.id.replace('logs', '');
        setEditMonitor(monitors.find(m => m.id == id));
    }

    function onStopClick(event) {
        const id = event.target.id.replace('stop', '');
        stopMonitor(id)
            .then(monitor => { window.location.reload() })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onStartClick(event) {
        const id = event.target.id.replace('start', '');
        startMonitor(id)
            .then(monitor => { window.location.reload() })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message)
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onDeleteClick(event) {
        const id = event.target.id.replace('delete', '');
        deleteMonitor(id)
            .then(monitor => { window.location.reload() })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onMonitorSubmit(order) {
        window.location.reload();
    }

   return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4"><FcTabletAndroid /> Monitores</h1>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewMonitorButton />
                        </div>
                    </div>
                </div>
                <nav>
                    <div className="nav nav-tabs mb-4 flex-column flex-sm-row" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="Monitors-tab" data-bs-toggle="tab" href="#Monitors" role="tab" aria-controls="Monitors" aria-selected="true"><FcTabletAndroid /> Crypto Monitores</a>
                        {/*
                        <a className="nav-item nav-link" id="Aba2-tab" data-bs-toggle="tab" href="#Aba2" role="tab" aria-controls="Aba2" aria-selected="false"><FcPuzzle /> Aba 2</a>
                        <a className="nav-item nav-link" id="Aba3-tab" data-bs-toggle="tab" href="#Aba3" role="tab" aria-controls="Aba3" aria-selected="false"><FcPlus /> Aba 3</a>
                        */}
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="Monitors" role="tabpanel" aria-labelledby="Monitors-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow mb-1">
                                    <div className="btn-toolbar mb-md-0">
                                        <div className="ms-2 ms-lg-3">
                                            {/*}
                                            <SearchMonitors placeholder="Filtrar Monitor" onChange={onSearchChange} />
                                            */}
                                        </div>
                                    </div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="text-center">
                                                <th className="border-gray-200"><FcTabletAndroid /> Monitores</th>
                                                <th className="border-gray-200"><FcCurrencyExchange /> Cryptos</th>
                                                <th className="border-gray-200"><FcElectricity /> Ativação</th>
                                                <th className="border-gray-200"><FcSupport /> Controles</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                monitors && monitors.length
                                                    ? monitors.map(monitor => (<MonitorRow key={monitor.id} data={monitor} onEditClick={onEditClick} onStartClick={onStartClick} onStopClick={onStopClick} onDeleteClick={onDeleteClick} onLogsClick={onLogsClick} />))
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                    <Pagination count={count} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Aba2" role="tabpanel" aria-labelledby="Aba2-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Detalhes da Aba 2</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Não faz Nada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Aba3" role="tabpanel" aria-labelledby="Aba3-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Detalhes da Aba 3</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Não faz Nada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
            <MonitorModal data={editMonitor} onSubmit={onMonitorSubmit} />
            <LogModal file={editMonitor.id > 0 ? "M" + editMonitor.id : ""} />
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}

export default Monitors;