import React from 'react';
import { FcElectronics, FcGrid, FcPlanner } from 'react-icons/fc';

/**
 * props:
 * - onClick
 */
function NewAutomationButton(props) {
    return (
        <>
            <button id="btnNewAutomation" className="btn btn-primary dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                <FcElectronics />
                Nova Automação
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a id="linkRegular" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalAutomation" onClick={props.onClick}><FcElectronics /> Automação Normal</a></li>
                <li><a id="linkGrid" className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#modalGrid" onClick={props.onClick}><FcGrid /> Automação Grid</a></li>
                {
                    localStorage.getItem("hasLaunch") === "true"
                        ? <li><a id="linkSchedule" className="dropdown-item" href="/automations/launch"><FcPlanner /> Lançamentos Binance</a></li>
                        : <></>
                }
            </ul>
        </>
    )
}

export default NewAutomationButton;