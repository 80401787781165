import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { doLogout } from '../../services/AuthService';
import SideBarItem from './SideBarItem';
import { FcServices, FcMultipleInputs, FcElectricalSensor, FcMoneyTransfer, FcElectronics, FcCandleSticks, FcViewDetails, FcTimeline, FcBullish, FcDataProtection, FcSelfServiceKiosk, FcTodoList, FcCurrencyExchange } from 'react-icons/fc';
import { GiExitDoor } from 'react-icons/gi';


function SideBar() {
    const navigate = useNavigate();

    function cleanAndRedirect() {
        localStorage.removeItem('token');
        navigate('/');
    }

    function onLogoutClick(event) {
        doLogout()
            .then(response => cleanAndRedirect())
            .catch(error => {
                console.error(error);
                cleanAndRedirect();
            })
    }

    return (
        <nav id="sidebarMenu" className="sidebar d-lg-block bg-gray-800 text-white collapse" datasimplebar="true">
            <div className="sidebar-inner px-4 pt-3">
                <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                    <div className="collapse-close d-md-none">
                        <a href="#sidebarMenu" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                            aria-expanded="true" aria-label="Toggle navigation">
                            <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                </div>
                <ul className="nav flex-column pt-3 pt-md-0">
                    <li className="nav-item">

                        <Link to="/dashboard" className="nav-link d-flex align-items-center">
                            <div>

                                <span className="mt-1 ms-1 sidebar-text" style={{ display: 'block', fontSize: '16px' }}>
                                    <img src="/img/favicon/beholder3.png" height="32" width="32" alt="Nome"></img> Beholder Trader 3.2
                                </span>
                                <span className="mt-1 ms-1 sidebar-text" style={{ display: 'block', fontSize: '12px' }}>
                                    <img src="/img/favicon/usuario.png" height="16" width="16" alt="Nome"></img> {localStorage.getItem("name")}
                                </span>
                                <span className="mt-1 ms-1 sidebar-text" style={{ display: 'block', fontSize: '12px' }}>
                                    <img src="/img/favicon/email2.png" height="16" width="16" alt="Email"></img> {localStorage.getItem("email")}
                                </span>

                            </div>



                        </Link>
                    </li>
                    <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700"></li>
                    <SideBarItem to="/dashboard" text="Dashboard">
                        <FcBullish />
                    </SideBarItem>
                    <SideBarItem to="/reports" text="Relatórios">
                        <FcViewDetails />
                    </SideBarItem>
                    <SideBarItem to="/wallet" text="Carteira">
                        <FcMoneyTransfer />
                    </SideBarItem>
                    <SideBarItem to="/orders" text={localStorage.getItem("hasFutures") === 'true' ? "Ordens & Posições" : "Ordens"}>
                        <FcCandleSticks />
                    </SideBarItem>
                    <SideBarItem to="/strategies" text="Estratégias">
                        <FcElectricalSensor />
                    </SideBarItem>
                    <SideBarItem to="/automations" text="Automações">
                        <FcElectronics />
                    </SideBarItem>
                    <li className="nav-item">
                        <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-templates">
                            <span>
                                <FcDataProtection />
                                <span className="sidebar-text"> Modelos Auto</span>
                            </span>
                            <span className="link-arrow">
                                <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>
                                </svg>
                            </span>
                        </span>
                        <div className="multi-level collapse" role="list" id="submenu-templates">
                            <ul className="flex-column nav">
                                <SideBarItem to="/orderTemplates" text="De Ordens">
                                    <FcTimeline />
                                </SideBarItem>
                                <SideBarItem to="/webhooks" text="De WebHook">
                                    <FcMultipleInputs />
                                </SideBarItem>
                                <SideBarItem to="/withdrawTemplates" text="De Saques">
                                    <FcCurrencyExchange />
                                </SideBarItem>
                            </ul>
                        </div>
                    </li>
                    <SideBarItem to="/monitors" text="Monitores">
                        <FcSelfServiceKiosk />
                    </SideBarItem>
                    <SideBarItem to="/settings" text="Configurações">
                        <FcServices />
                    </SideBarItem>
                    <SideBarItem to="/" text="Sair" onClick={onLogoutClick}>
                        <GiExitDoor />
                    </SideBarItem>
                    <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700"></li>

                </ul>
            </div>
        </nav >
    );
}

export default SideBar;