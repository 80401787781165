import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import Toast from '../../components/Toast';
import SelectSymbol from '../../components/SelectSymbol';
import MyStrategies from './MyStrategies/MyStrategies';
import SharedStrategies from './SharedStrategies/SharedStrategies';
import { FcElectricalSensor } from 'react-icons/fc';

function Strategies() {

    const [symbol, setSymbol] = useState('');

    const [notification, setNotification] = useState({ type: '', text: '' });

    function onSymbolChange(event) {
        setSymbol(event.target.value);
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4"><FcElectricalSensor /> Minhas Estratégias</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <div className="me-2 mt-3">
                                <SelectSymbol symbol={symbol} disabled={false} onChange={onSymbolChange} showAny={false} onlyFavorites={false} />
                            </div>
                            <button id="btnNewStrategy" className="btn btn-primary animate-up-2" data-bs-toggle="modal" data-bs-target="#modalStrategy">
                                <FcElectricalSensor /> <b> </b>
                                Nova Estratégia
                            </button>
                        </div>
                    </div>
                </div>
                <MyStrategies symbol={symbol} onNotification={setNotification} />
                <SharedStrategies symbol={symbol} onNotification={setNotification} />
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}

export default Strategies;
