import React from 'react';
import { FcTabletAndroid } from 'react-icons/fc';
/**
 * props:
 * - onClick
 */
function NewMonitorButton() {
    return (
        <button id="btnNewMonitor" className="btn btn-primary animate-up-2" data-bs-toggle="modal" data-bs-target="#modalMonitor">
            <FcTabletAndroid />
            Novo Monitor
        </button>
    )
}

export default NewMonitorButton;