import React, { useState, useEffect, useRef } from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import Toast from '../../components/Toast';
import { getSettings, updateSettings } from '../../services/SettingsService';
import { FcBusinessman, FcLibrary, FcKey, FcServices, FcMultipleSmartphones } from 'react-icons/fc';
import { FaBitcoin } from 'react-icons/fa';

function Settings() {

    const confirmPassword = useRef('');
    const [settings, setSettings] = useState({});
    const [notification, setNotification] = useState({});

    useEffect(() => {

        getSettings()
            .then(result => setSettings(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }, []);

    function onInputChange(event) {
        setSettings(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function onFormSubmit(event) {
        if ((settings.password || confirmPassword.current.value)
            && settings.password !== confirmPassword.current.value)
            return setNotification({ type: 'error', text: `Os campos Nova Senha e Confirmar Senha devem ser iguais.` });

        updateSettings(settings)
            .then(result => {
                if (result)
                    setNotification({ type: 'success', text: `Configurações salvas com sucesso!` });
                else
                    setNotification({ type: 'error', text: result });
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h1 className="h4"><FcServices /> Configurações</h1>
                    </div>

                </div>

                <nav>
                    <div className="nav nav-tabs mb-4 flex-column flex-sm-row" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="Usuarios-tab" data-bs-toggle="tab" href="#Usuarios" role="tab" aria-controls="Usuarios" aria-selected="true"><FcBusinessman /> Usuário</a>
                        <a className="nav-item nav-link" id="Exchange-tab" data-bs-toggle="tab" href="#Exchange" role="tab" aria-controls="Exchange" aria-selected="false"><FcKey /> Binance Exchange</a>
                        {/*<a className="nav-item nav-link" id="CryptoTab-tab" data-bs-toggle="tab" href="#CryptoTab" role="tab" aria-controls="CryptoTab" aria-selected="false"><FaBitcoin /> Cryptomoedas e Tokens</a>*/}
                        {/*<a className="nav-item nav-link" id="ConfAlerta-tab" data-bs-toggle="tab" href="#ConfAlertaTab" role="tab" aria-controls="ConfAlertaTab" aria-selected="false"><FcMultipleSmartphones /> Configurações de Alertas</a> */}
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="Usuarios" role="tabpanel" aria-labelledby="Usuarios-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow mb-1">
                                    <h2 className="h5 mb-4"><FcBusinessman /> Usuário</h2>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input className="form-control" id="email" type="email" placeholder="seuemail@dominio.com" value={settings.email || ''} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="phone">Telefone</label>
                                                <input className="form-control" id="phone" type="text" placeholder="+55 27 123456789" value={settings.phone || ''} onChange={onInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div>
                                                <label htmlFor="password">Nova Senha</label>
                                                <input className="form-control" id="password" type="password" placeholder="Digite sua Senha" value={settings.password || ''} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div>
                                                <label htmlFor="confirmPassword">Confirmar Nova Senha</label>
                                                <input ref={confirmPassword} className="form-control" id="confirmPassword" type="password" placeholder="Digite sua Senha" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="telegramChat">Telegram Chat ID</label><a href={"https://t.me/" + settings.telegramBot} target="_blank" className="badge bg-secondary py-1 ms-1">?</a>
                                                <input className="form-control" id="telegramChat" type="text" placeholder="Entre com o Telegram Chat ID" value={settings.telegramChat || ''} onChange={onInputChange} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="limit">Permissões</label>
                                                <input className="form-control" id="limit" type="text" disabled={true} value={settings.limit ? settings.limit.name : ''} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>Salvar</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div className="tab-pane fade" id="Exchange" role="tabpanel" aria-labelledby="Exchange-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Binance Exchange - Informações de Acesso</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="acceesKey">Spot Access Key</label>
                                        <input className="form-control" id="accessKey" type="text" placeholder="Entre com API Access Key de Spot" value={settings.accessKey || ''} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="secretKey">Nova Spot Secret Key</label>
                                        <input className="form-control" id="secretKey" type="password" placeholder="Entre com API Secret Key de Spot" value={settings.secretKey || ''} onChange={onInputChange} />
                                    </div>
                                </div>
                            </div>
                            {
                                settings && settings.limit && settings.limit.hasFutures
                                    ? (
                                        <>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="accessKey">Futuros Access Key</label>
                                                        <input className="form-control" id="futuresKey" type="text" placeholder="Entre com API Access Key de Futuros" value={settings.futuresKey || ''} onChange={onInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor="secretKey">Nova Futuros Secret Key</label>
                                                        <input className="form-control" id="futuresSecret" type="password" placeholder="Entre com API Secret Key de Futuros" value={settings.futuresSecret || ''} onChange={onInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    : <></>
                            }
                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>Salvar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="ConfAlertaTab" role="tabpanel" aria-labelledby="ConfAlerta-tab">
                        <div className="card card-body border-0 shadow mb-1">

                        </div>
                    </div>
                </div>
                <Footer />
            </main>
            <Toast type={notification.type} text={notification.text} />
        </>
    )
}

export default Settings;