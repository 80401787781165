import React from 'react';
import { FcSearch } from 'react-icons/fc';

/**
 * props:
 * - placeholder
 * - onChange
 */
function SearchSymbol(props) {

    function getPlaceholder(){
        return props.placeholder ? props.placeholder : 'Filtrar por Crypto';
    }

    return (
        <form className="navbar-search form-inline" id="navbar-search-main">
            <div className="input-group input-group-merge search-bar">
                <span className="input-group-text" id="topbar-addon">
                    <FcSearch />
                </span>
                <input type="text" className="form-control" id="search" placeholder={getPlaceholder()}
                    onChange={props.onChange} />
            </div>
        </form>
    )
}

export default SearchSymbol;
