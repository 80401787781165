import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import SearchSymbol from '../../components/SearchSymbol';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import OrderRow from './OrderRow';
import { getOrders } from '../../services/OrdersService';
import Pagination from '../../components/Pagination';
import ViewOrderModal from './ViewOrderModal';
import Toast from '../../components/Toast';
import PositionsTable from '../Futures/PositionsTable';
import { FcMoneyTransfer, FcCandleSticks, FcSalesPerformance, FcKey, FcCurrencyExchange, FcCalendar, FcRefresh } from 'react-icons/fc';
import { FcPlus, FcBullish, FcStatistics, FcDocument } from 'react-icons/fc';

function Orders() {

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    const navigate = useNavigate();

    useEffect(() => {
        setPage(getPage(defaultLocation));
    }, [defaultLocation])

    const { symbol } = useParams();

    const [search, setSearch] = useState(symbol ? symbol : '');

    const [orders, setOrders] = useState([]);

    const [notification, setNotification] = useState([]);

    const [count, setCount] = useState(0);

    const [viewOrder, setViewOrder] = useState({});

    const [page, setPage] = useState(getPage());

    const [isLoading, setIsLoading] = useState(false);

    const [refresh, setRefresh] = useState(0);

    function showFutures() {
        return window.location.href.indexOf('forders') !== -1;
    }

    useEffect(() => {
        setIsLoading(true);
        getOrders(search, page || 1, showFutures())
            .then(result => {
                setOrders(result.rows);
                setCount(result.count);
                setViewOrder(result.rows[0]);
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
                setIsLoading(false);
            })

    }, [search, page, refresh])

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function onViewClick(event) {
        const id = event.target.id.replace('view', '');
        const order = orders.find(o => o.id == id);
        // eslint-disable-next-line
        setViewOrder({ ...order });
    }

    function onOrderSubmit(order) {
        setRefresh(Date.now());
    }

    function toggleMarket() {
        if (showFutures())
            navigate('/orders/');
        else
            navigate('/forders/');
        setRefresh(Date.now());
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">{showFutures() ? "Futuros " : "Spot "}Ordens</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        {
                            localStorage.getItem("hasFutures") === "true"
                                ? (
                                    <div className='me-2 mb-2'>
                                        <button className='btn btn-secondary me-2' onClick={toggleMarket}>
                                            {
                                                showFutures()
                                                    ? "Futuros"
                                                    : "Spot"
                                            }
                                        </button>
                                    </div>
                                )
                                : <></>
                        }
                        <div className="d-inline-flex align-items-center me-2 mb-3">
                            <NewOrderButton />
                        </div>

                    </div>
                </div>

                <nav>
                    <div className="nav nav-tabs mb-4 flex-column flex-sm-row" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="OrdensCrypto-tab" data-bs-toggle="tab" href="#OrdensCrypto" role="tab" aria-controls="OrdensCrypto" aria-selected="true"><FcCandleSticks /> Ordens Crypto</a>
                        {/*}
                        <a className="nav-item nav-link" id="Aba2-tab" data-bs-toggle="tab" href="#Aba2" role="tab" aria-controls="Aba2" aria-selected="false"><FcPuzzle /> Aba 2</a>
                        <a className="nav-item nav-link" id="Aba3-tab" data-bs-toggle="tab" href="#Aba3" role="tab" aria-controls="Aba3" aria-selected="false"><FcPlus /> Aba 3</a>
                        */}
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="OrdensCrypto" role="tabpanel" aria-labelledby="OrdensCrypto-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow mb-1">
                                    <div className="btn-toolbar mb-md-0">
                                        <div className="ms-2 ms-lg-3">
                                            <SearchSymbol placeholder="Filtrar Crypto" onChange={onSearchChange} />
                                        </div>
                                    </div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="text-center">
                                                <th className="border-gray-200"><FcCurrencyExchange /> Crypto</th>
                                                <th className="border-gray-200"><FcCalendar /> Data</th>
                                                {/*<th className="border-gray-200"><FcRefresh /> Compra/Venda</th>*/}
                                                <th className="border-gray-200"><FcSalesPerformance /> Quantidade</th>
                                                <th className="border-gray-200"><FcBullish /> Líquido Tirando Comissão (Net)</th>
                                                <th className="border-gray-200"><FcStatistics /> Status</th>
                                                <th className="border-gray-200"><FcDocument /> Detalhes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !isLoading && orders && orders.length
                                                    ? orders.map(order => (<OrderRow key={order.clientOrderId} data={order} onClick={onViewClick} />))
                                                    : <tr><td colSpan={6}>Carregando...</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                    <Pagination count={count} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Aba2" role="tabpanel" aria-labelledby="Aba2-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Detalhes da Aba 2</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Não faz Nada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Aba3" role="tabpanel" aria-labelledby="Aba3-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Detalhes da Aba 3</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Não faz Nada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showFutures()
                        ? <PositionsTable />
                        : <></>
                }
                <Footer />
            </main>
            <ViewOrderModal data={viewOrder} onUpdate={onOrderSubmit} />
            <NewOrderModal onSubmit={onOrderSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}

export default Orders;