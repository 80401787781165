import React from 'react';
import { FcCurrencyExchange, FcBullish, FcStatistics, FcDocument } from 'react-icons/fc';

/**
 * props:
 * - onClick
 */
function NewWithdrawTemplateButton() {
    return (
        <button id="btnNewWithdrawTemplate" className="btn btn-primary animate-up-2" data-bs-toggle="modal" data-bs-target="#modalWithdrawTemplate">
            <FcCurrencyExchange /><b> </b>
            Novo Modelo de Saque
        </button>
    );
}

export default NewWithdrawTemplateButton;