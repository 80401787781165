import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer';
import AutomationModal from './AutomationModal/AutomationModal';
import GridModal from './GridModal/GridModal';
import AutomationRow from './AutomationRow';
import { getAutomations, startAutomation, stopAutomation, deleteAutomation } from '../../services/AutomationsService';
import Pagination from '../../components/Pagination';
import Toast from '../../components/Toast';
import NewAutomationButton from './NewAutomationButton';
import BacktestButton from './BacktestModal/BacktestButton';
import BacktestModal from './BacktestModal/BacktestModal';
import { FcPuzzle, FcSalesPerformance, FcKey, FcCurrencyExchange, FcCalendar, FcRefresh } from 'react-icons/fc';
import { FcOk, FcElectronics, FcFlashOn, FcDocument, FcTabletAndroid } from 'react-icons/fc';


function Automations() {

    const navigate = useNavigate();

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        setPage(getPage(defaultLocation));
    }, [defaultLocation])

    const [automations, setAutomations] = useState([]);

    const [count, setCount] = useState(0);

    const [notification, setNotification] = useState({ type: '', text: '' });

    const DEFAULT_AUTOMATION = {
        symbol: "",
        conditions: "",
        actions: [],
        name: '',
        indexes: ''
    }

    const [editAutomation, setEditAutomation] = useState(DEFAULT_AUTOMATION);

    const [page, setPage] = useState(getPage());

    useEffect(() => {
        getAutomations(page || 1)
            .then(result => {
                setAutomations(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page])

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        const automation = automations.find(m => m.id == id);

        if (automation.schedule)
            navigate("/automations/launch/" + id);
        else
            setEditAutomation({ ...automation });
    }

    function onStopClick(event) {
        const id = event.target.id.replace('stop', '');
        stopAutomation(id)
            .then(automation => { window.location.reload() })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message)
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onStartClick(event) {
        const id = event.target.id.replace('start', '');
        startAutomation(id)
            .then(automation => { window.location.reload() })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message)
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onDeleteClick(event) {
        const id = event.target.id.replace('delete', '');
        deleteAutomation(id)
            .then(automation => { window.location.reload() })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message)
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onAutomationSubmit(automation) {
        window.location.reload();
    }

    function onNewAutomationClick(event) {
        if (event.target.id === 'linkSchedule')
            setEditAutomation({ ...DEFAULT_AUTOMATION, schedule: new Date().toISOString() });
        else
            setEditAutomation({ ...DEFAULT_AUTOMATION });
    }

    return (
        <>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4"><FcElectronics /> Automações</h2> 
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <BacktestButton />
                            <NewAutomationButton onClick={onNewAutomationClick} />
                        </div>
                    </div>
                </div>
                <nav>
                    <div className="nav nav-tabs mb-4 flex-column flex-sm-row" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="Automations-tab" data-bs-toggle="tab" href="#Automations" role="tab" aria-controls="Automations" aria-selected="true"><FcElectronics /> Automações</a>
                        {/*
                        <a className="nav-item nav-link" id="Aba2-tab" data-bs-toggle="tab" href="#Aba2" role="tab" aria-controls="Aba2" aria-selected="false"><FcPuzzle /> Aba 2</a>
                        <a className="nav-item nav-link" id="Aba3-tab" data-bs-toggle="tab" href="#Aba3" role="tab" aria-controls="Aba3" aria-selected="false"><FcPlus /> Aba 3</a>
                        */}
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="Automations" role="tabpanel" aria-labelledby="Automations-tab">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="text-center">
                                                <th className="border-gray-200"><FcElectronics /> Crypto</th>
                                                <th className="border-gray-200"><FcOk /> Nome</th>
                                                <th className="border-gray-200"><FcFlashOn /> Status</th>
                                                <th className="border-gray-200">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                automations && automations.length
                                                    ? automations.map(automation => (<AutomationRow key={automation.id} data={automation} onEditClick={onEditClick} onStartClick={onStartClick} onStopClick={onStopClick} onDeleteClick={onDeleteClick} />))
                                                    : <></>
                                            }
                                        </tbody>
                                    </table>
                                    <Pagination count={count} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Aba2" role="tabpanel" aria-labelledby="Aba2-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Detalhes da Aba 2</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Não faz Nada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Aba3" role="tabpanel" aria-labelledby="Aba3-tab">
                        <div className="card card-body border-0 shadow mb-1">
                            <h2 className="h5 my-4"><FcKey /> Detalhes da Aba 3</h2>
                            <div className="row">
                                <div className="col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                            <div className="col-sm-3">
                                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Não faz Nada</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
            <AutomationModal data={editAutomation} onSubmit={onAutomationSubmit} />
            <GridModal data={editAutomation} onSubmit={onAutomationSubmit} />
            <BacktestModal />
            <Toast type={notification.type} text={notification.text} />
        </>
    );
}

export default Automations;