import React from 'react';
import { FcMultipleInputs } from 'react-icons/fc';

function NewWebHookButton() {
    return (
        <button id="btnNewWebHook" className="btn btn-primary animate-up-2" data-bs-toggle="modal" data-bs-target="#modalWebHook">
            <FcMultipleInputs /> <b> </b>
            Novo WebHook
        </button>
    )
}

export default NewWebHookButton;