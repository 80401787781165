import React from 'react';
import NavBar from './NavBar';
import Sidebar from './SideBar.js';

function Menu() {
    return (<>
        <NavBar />
        <Sidebar />
    </>)
}

export default Menu;