import React from 'react';
import { FcBusinessman, FcAndroidOs } from 'react-icons/fc';


function OrderRow(props) {


    /*function getDate(timestamp) {
        const date = new Date(Number(timestamp));
        const frm = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short' }).format(date);
        return frm;
    }*/
    function getDate(timestamp) {
        if (!timestamp) return "";
        const date = new Date(timestamp);
    
        // Formata a data no estilo dd/mm/yyyy
        const formattedDate = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short' }).format(date);
    
        // Formata o tempo no estilo hh:mm:ss
        const formattedTime = date.toLocaleTimeString('en-GB', { hour12: false });
    
        // Obtém os milissegundos
        const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
    
        // Concatena data, tempo e milissegundos
        return `${formattedDate}, ${formattedTime}.${milliseconds}`;
    }
    

    function getStatusClass(status) {
        switch (status) {
            case 'PARTIALLY_FILLED': return 'badge bg-info';
            case 'NEW': return 'badge bg-warning text-dark';
            case 'FILLED': return 'badge bg-success';
            case 'BUY': return 'badge bg-success';
            case 'SELL': return 'badge bg-danger';
            case 'REJECTED': return 'badge bg-secondary';
            case 'EXPIRED': return 'badge bg-light text-dark';
            case 'CANCELED': return 'badge bg-danger'
            default: return 'badge bg-dark'
        }
    }

    function getTranslate(props) {
        switch (props) {
            case 'BUY': return 'COMPRA';
            case 'SELL': return 'VENDA';
            case 'NEW': return 'NOVA';
            case 'FILLED': return 'EXECUTADA';
            case 'PARTIALLY_FILLED': return 'PARCIALMENTE-EXECUTADA';
            case 'REJECTED': return 'REJEITADA';
            case 'EXPIRED': return 'EXPIRADA';
            case 'LIMIT': return 'LIMITADA';
            case 'CANCELED': return 'CANCELADA';
            case 'MARKET': return 'A MERCADO';
            default: return 'NÃO IDENTIFICADA'
        }
    }

    return (
        <tr className="text-center">
            <td>{
                props.data.automationId
                    ? (
                        <FcAndroidOs />
                    )
                    : (
                        <FcBusinessman />
                    )
            }{
                    props.data.side === 'BUY'
                        ? <span className="badge bg-success py-1 me-2">COMPRA</span>
                        : <span className="badge bg-danger py-1 me-2">VENDA</span>
                }
                {props.data.symbol}
            </td>
            <td><span className="fw-normal">{getDate(props.data.transactTime)}</span></td>
            <td><span className="fw-normal">{props.data.quantity}</span></td>
            <td><span className="fw-bold">{props.data.net}</span></td>
            <td><span className={getStatusClass(props.data.status)}>{getTranslate(props.data.status)}</span></td>
            <td>
                <button id={"view" + props.data.id} type="button" className="btn btn-info btn-xs" title="Visualizar Ordem" data-bs-toggle="modal" data-bs-target="#modalViewOrder" onClick={props.onClick}>
                    <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /></svg>
                </button>
            </td>
        </tr>

    )
}

export default OrderRow;